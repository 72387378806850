import * as React from 'react';
import {
    Button,
    Card,
    CardActionArea,
    CardContent,
    Checkbox,
    createStyles,
    FormControl,
    Grid,
    InputBase,
    InputLabel,
    ListItemText,
    MenuItem,
    Paper,
    Select,
    Step,
    StepLabel,
    Stepper,
    TextField,
    Theme,
    Typography,
    withStyles
} from '@material-ui/core';
import { WithStyles } from '@material-ui/styles';
import { Account, Folder } from '../../shared/domain';
import { Marker } from '../../models';
import { NodesTreeView, RenderTree } from '../../shared/components/node-tree';
import { PickedType } from '../../features/marker-export/marker-export';
import AccountProvider from '../../providers/account.provider';
import FolderProvider from '../../providers/folder.provider';
import _ from 'lodash';
import { SimpleListPagination } from '../../shared/components/simple-list-pagination';
import { SimpleListItem } from '../../shared/components/simple-list';
import FloorplanProvider from '../../providers/floorplan.provider';
import { CCSpinner } from '../../shared/components/cc-spinner';
import { Alert, AlertTitle } from '@material-ui/lab';
import JobProvider, { JobRequest, JobTypes, RunningTasks } from '../../providers/job.provider';
import {
    MarkerSearchCategory,
    MarkerSourceItem
} from '../../features/marker-detail/marker-detail';
import { PublishRounded } from '@material-ui/icons';
import Papa from 'papaparse';
import { RootStore } from '../../stores/root.store';
import { SimpleDialog } from '../../shared/components/simple-dialog';

interface SimpleMarkerDoc {
    _id: string;
    marker_id: string;
    name: string;
}

interface MarkerList {
    folder: string;
    floorplan: string;
    checked: boolean;
    marker: SimpleMarkerDoc;
    value: UpdateField[];
}

interface UpdateField {
    field: available_meta_fiels;
    label: string;
}

enum WizardSteps {
    MarkerOrigin = 0,
    MarkerSet,
    FieldOperation,
    Confirmation,
    Result
}

type available_meta_fiels = 'type' | 'square_footage';

const WIZARD_STEPS = [
    {
        id: WizardSteps.MarkerOrigin,
        label: 'Marker origins'
    },
    {
        id: WizardSteps.MarkerSet,
        label: 'Select markers'
    },
    {
        id: WizardSteps.FieldOperation,
        label: 'Select field and fill values'
    },
    {
        id: WizardSteps.Confirmation,
        label: 'Confirm changes'
    },
    {
        id: WizardSteps.Result,
        label: 'Results'
    }
];
const AVAILABLE_FIELDS: UpdateField[] = [
    { field: 'type', label: 'Room Type' },
    { field: 'square_footage', label: 'Square Footage' }
];

const styles = (theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            flexWrap: 'wrap'
        },
        container: {
            width: '100%',
            display: 'flex',
            flexDirection: 'column'
        },
        transferButton: {
            padding: '0.5em 0',
            margin: '1em 0'
        },
        treePaper: {
            height: '90%',
            maxHeight: '35em',
            overflowY: 'scroll',
            [theme.breakpoints.down('lg')]: {
                maxHeight: '32em'
            }
        },
        treeContainer: {
            background: '#fff',
            padding: '1em',
            height: '100%'
        },
        heading: {
            fontWeight: 700,
            marginBottom: '0.5em',
            marginTop: '0.5em'
        },
        simpleHeading: {
            marginBottom: '0.5em',
            marginTop: '0.5em'
        },
        ellipsis: {
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden'
        },
        simpleList: {
            overflow: 'auto',
            height: '24em',
            width: '100%'
        },
        stepsContentHolder: {
            padding: '1em',
            height: '40em'
        },
        stepsHolder: {
            paddingBottom: 0
        },
        gridHolders: {
            height: '95%'
        },
        nextButton: {
            width: '8em',
            position: 'absolute',
            right: '1em',
            bottom: '1em'
        },
        progressContainer: {
            flexGrow: 1
        },
        markerCard: {
            position: 'relative',
            borderRadius: 0
        },
        markerCardBody: {
            padding: '0.5em !important',
            '& h6': {
                fontSize: '1.3em'
            }
        },
        markerCheckbox: {
            position: 'absolute',
            right: '1em'
        },
        emptyMarkerHolder: {
            height: '35em',
            display: 'flex',
            alignItems: 'center',
            '& h1': {
                width: '100%',
                textAlign: 'center',
                color: 'gray'
            }
        },
        footNote: {
            float: 'right',
            marginTop: '0.5em',
            color: 'gray',
            fontWeight: 'bold'
        },
        textField: {
            padding: '1em',
            width: '100%',
            '& label': {
                fontWeight: 'bolder',
                padding: '1em 0 0 1em',

                fontSize: '1.4em',
                color: 'rgba(0, 0, 0, 0.8) !important'
            },
            '&::before': {
                borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
                borderBottomStyle: 'solid'
            }
        },
        searchInputHolder: {
            display: 'flex',
            alignItems: 'center',
            marginBottom: '5px'
        },
        searchCategoryInput: {
            width: '40%'
        },
        metaFieldInput: {
            margin: '1em auto',
            display: 'block',
            width: '60%',
            '& .MuiInput-formControl': {
                width: '100%'
            }
        },
        searchInput: {
            borderBottom: '1px solid #009933',
            borderLeft: '1px solid #009933',
            padding: '7px'
            // width: '100%'
        },
        uploadCSVButton: {
            width: 'auto',
            position: 'absolute',
            left: '1em',
            bottom: '1em'
        },
        allMarkersButton: {
            width: 'auto',
            position: 'absolute',
            left: '18em',
            bottom: '1em'
        },
        iconKerning: {
            marginRight: '0.5em'
        },
        input: {
            display: 'none',
        },
        tableInput: {
            width: '90%'
        },
        tableInputLast: {
            width: '100%'
        },
        tablePaper: {
            maxHeight: '70%',
            padding: '0 2em 2em 2em',
            overflowY: 'scroll'
        },
        bottomBorder: {
            borderBottom: '1px solid lightgray',
            paddingBottom: '0.5em !important',
            paddingTop: '0.5em !important',
        },
        bottomLeft: {
            borderBottom: '1px solid lightgray',
            borderLeft: '1px solid lightgray',
            paddingLeft: '1.5em !important',
            paddingBottom: '0.5em !important',
            paddingTop: '0.5em !important',
        }
    });

interface Props extends WithStyles<typeof styles> {
    className?: string;
    rootStore: RootStore;
    currentAccount: Account;
    currentNodeTree: RenderTree;
    folders: Folder[];
    accountProvider: AccountProvider;
    folderProvider: FolderProvider;
    floorplanProvider: FloorplanProvider;
    jobProvider: JobProvider;
    processJobs?: (
        jobs: RunningTasks[],
        sourceList: MarkerSourceItem[]
    ) => void;
}

interface States {
    sourceList: MarkerSourceItem[];
    allMarkers: MarkerList[];
    searchMarkers: MarkerList[];
    operationMarkers: MarkerList[];
    allOperationMarkers: MarkerList[];
    allOperationMarkersError: boolean;
    currentStage: WizardSteps;
    currentNodeTree: RenderTree;
    treeIsLoading: boolean;
    treePickedType: PickedType;
    treePickedId: string;
    treePickedName: string;
    folders: Folder[];
    processedFolders: string[];
    processedFoldersWMarkers: string[];
    allFolders: Folder[];
    isContentLoading: boolean;
    producedJobsList: RunningTasks[];
    processedMarkers: number;
    failedMarkers: number;
    allMarkersSearch: string;
    allMarkersSearchCategory: MarkerSearchCategory;
    selectedMarkersSearch: string;
    selectedMarkersSearchCategory: MarkerSearchCategory;
    treeScrollPosition: number;
    selectedField: UpdateField | undefined;
    isNotificationModalOpened: boolean;
    notificationModalHeader: string;
    notificationModalText: string;
}

class MarkerMetaFieldsWizard extends React.Component<Props, States> {

    state: States = {
        sourceList: [],
        allMarkers: [],
        searchMarkers: [],
        operationMarkers: [],
        allOperationMarkers: [],
        allOperationMarkersError: false,
        currentStage: WizardSteps.MarkerOrigin,
        currentNodeTree: { id: '', name: '', children: [], path: '' },
        treeIsLoading: false,
        treePickedType: PickedType.None,
        treePickedId: '',
        treePickedName: '',
        processedFolders: [],
        processedFoldersWMarkers: [],
        folders: [],
        allFolders: [],
        isContentLoading: false,
        producedJobsList: [],
        processedMarkers: 0,
        failedMarkers: 0,
        allMarkersSearch: '',
        allMarkersSearchCategory: 'MARKER_NAME',
        selectedMarkersSearch: '',
        selectedMarkersSearchCategory: 'MARKER_NAME',
        treeScrollPosition: 0,
        selectedField: AVAILABLE_FIELDS[0],
        isNotificationModalOpened: false,
        notificationModalHeader: '',
        notificationModalText: '',
    };

    componentDidMount() {
        this.setUpInitialTree();
        this.loadAllAccountFolder();
    }

    setUpInitialTree = () => {
        // COPY THE ALREADY NAVIGATED TREE FOR BETTER UX
        const { currentNodeTree, folders } = this.props;
        this.setState({ currentNodeTree, folders });
    };

    loadAllAccountFolder = () => {
        const { accountProvider, currentAccount } = this.props;
        this.setState({ treeIsLoading: true });

        accountProvider
            .getFoldersByAccountId(currentAccount.accountId)
            .then(data => {
                this.setState({
                    treeIsLoading: false,
                    allFolders: data
                });
            });
    };

    deepTreeSearch = (treeData: RenderTree, searchId: string) => {
        let foundTree: RenderTree | undefined;
        let searchLevel: RenderTree[] | undefined = treeData.children;
        do {
            foundTree = searchLevel?.find((e: RenderTree) => e.id === searchId);
            let tempLevel: any = [];
            searchLevel?.forEach((element: RenderTree) => {
                tempLevel = tempLevel.concat(element.children);
            });
            searchLevel = tempLevel;
        } while (!foundTree);
        return foundTree;
    };

    updateChildrenOnPath = (
        treeData: RenderTree,
        nodeId: string,
        children: RenderTree[]
    ) => {
        treeData.children!.forEach(element => {
            if (_.isEqual(element.id, nodeId)) {
                element.children = children;
            } else {
                this.updateChildrenOnPath(element, nodeId, children);
            }
        });
    };

    onNodeSelected = (
        treeData: RenderTree,
        nodeId: string,
        nodeLabel: string,
        updateCallback: (nodeIds: string[]) => void
    ) => {
        const { currentAccount, accountProvider, folderProvider } = this.props;
        const {
            folders,
            processedFolders,
            sourceList,
            processedFoldersWMarkers
        } = this.state;
        if (currentAccount!.accountId === nodeId) {
            this.setState({
                treePickedType: PickedType.None,
                treePickedId: '',
                treePickedName: ''
            });
            return;
        }
        this.setState({ treeIsLoading: true });
        // CHECK PICK TYPE
        const currentNodePos = this.deepTreeSearch(treeData, nodeId);
        const nodePickedType = folders.find(e => e.id === nodeId)
            ? !processedFoldersWMarkers.includes(nodeId)
                ? PickedType.None
                : PickedType.Folder
            : PickedType.FloorPlan;
        // NOT PROCESSED FOLDER
        if (nodePickedType === PickedType.None) {
            // DID WE ALREADY CHECK FOR CHILDREN?
            if (processedFolders.find(e => e === nodeId)) {
                // FOLDER CHILDREN
                // WE DONT DO FOLDERS FOR PDF
                this.setState({
                    treePickedType: PickedType.None,
                    treePickedId: '',
                    treePickedName: '',
                    currentNodeTree: treeData,
                    treeIsLoading: false
                });
                return;
            }
            // EMPTY
            // CHECK FOR FOLDER CHILDREN FIRST
            const currentPath = currentNodePos.path.slice(0, -2);
            processedFolders.push(nodeId);
            return accountProvider
                .getChildrenFolderByFolderId(currentAccount!.accountId, nodeId)
                .then((results: Folder[]) => {
                    if (results.length > 0) {
                        const nodes: RenderTree[] = results.map(element => {
                            const newItem = {
                                id: element.id,
                                name: element.name,
                                children: [],
                                path: `${currentNodePos.path}${element.id}__`
                            };
                            return newItem as RenderTree;
                        });
                        this.updateChildrenOnPath(treeData, nodeId, nodes);
                        updateCallback([
                            currentAccount!.accountId,
                            ...currentPath.split('__'),
                            ...nodes.map(e => e.id)
                        ]);
                        this.setState({
                            treePickedType: PickedType.None,
                            treePickedId: '',
                            treePickedName: '',
                            currentNodeTree: treeData,
                            treeIsLoading: false,
                            folders: folders.concat(results),
                            processedFolders
                        });
                        return;
                    } else {
                        return folderProvider
                            .fetchFloorplans(nodeId)
                            .then(floorplans => {
                                if (floorplans.length > 0) {
                                    const nodes: RenderTree[] = floorplans.map(
                                        (element: RenderTree) => {
                                            const newItem = {
                                                ...element
                                            } as RenderTree;
                                            newItem.children = [];
                                            newItem.path = `${currentNodePos.path}${element.id}__`;
                                            return newItem;
                                        }
                                    );
                                    this.updateChildrenOnPath(
                                        treeData,
                                        nodeId,
                                        nodes
                                    );
                                    updateCallback([
                                        currentAccount!.accountId,
                                        ...currentPath.split('__'),
                                        ...nodes.map(e => e.id)
                                    ]);
                                    // THIS FOLDER FOLDER HAS FLOORPLAN
                                    // IT IS NOT SELECTABLE
                                    this.setState({
                                        treePickedType: PickedType.None,
                                        treePickedId: '',
                                        treePickedName: '',
                                        currentNodeTree: treeData,
                                        treeIsLoading: false,
                                        processedFolders
                                    });
                                    return;
                                }
                                // NO FLOORPLANS FOUND
                                // CHECK IF IT HAS MARKERS
                                return folderProvider
                                    .fetchFolderMarkers(nodeId)
                                    .then(folderMarkers => {
                                        const { markers } = folderMarkers;

                                        if (markers.length <= 0) {
                                            // NO MARKERS FOUND
                                            // CAN'T DO ANYTHING
                                            this.setState({
                                                treePickedType: PickedType.None,
                                                treePickedId: '',
                                                treePickedName: '',
                                                currentNodeTree: treeData,
                                                treeIsLoading: false,
                                                processedFolders
                                            });
                                            return;
                                        }

                                        processedFoldersWMarkers.push(nodeId);
                                        if (
                                            !sourceList.find(
                                                e => e.id === nodeId
                                            )
                                        ) {
                                            sourceList.push({
                                                id: nodeId,
                                                name: nodeLabel,
                                                type: 'Folder'
                                            });
                                        }
                                        this.setState({
                                            treePickedType: PickedType.Folder,
                                            treePickedId: nodeId,
                                            treePickedName: currentNodePos.name,
                                            treeIsLoading: false,
                                            sourceList
                                        });

                                        return;
                                    });
                            });
                    }
                });
        }

        if (!sourceList.find(e => e.id === nodeId)) {
            const sourceType =
                nodePickedType === PickedType.FloorPlan
                    ? 'Floorplan'
                    : 'Folder';
            sourceList.push({ id: nodeId, name: nodeLabel, type: sourceType });
        }
        this.setState({
            treePickedType: nodePickedType,
            treePickedId: nodeId,
            treePickedName: currentNodePos.name,
            treeIsLoading: false,
            sourceList
        });

        return;
    };

    onNodeToggled = (nodeIds: string[]) => {
        return;
    };

    deleteSourceList = (listItem: SimpleListItem) => {
        const { sourceList } = this.state;

        const delIndex = sourceList.findIndex(e => e.id === listItem.id);

        if (delIndex >= 0) {
            sourceList.splice(delIndex, 1);
            this.setState({ sourceList });
        }

        return;
    };

    checkNextStageButton = () => {
        const { currentStage } = this.state;
        let flag = false;

        switch (currentStage) {
            case WizardSteps.MarkerOrigin:
                const { sourceList } = this.state;
                flag = sourceList.length <= 0;
                break;

            case WizardSteps.MarkerSet:
                const { operationMarkers } = this.state;
                flag = operationMarkers.length <= 0;
                break;

            case WizardSteps.Result:
                const { isContentLoading } = this.state;
                flag = isContentLoading;
                break;
            default:
                break;
        }

        return flag;
    };

    goNextStageButton = () => {
        const { currentStage, sourceList } = this.state;

        switch (currentStage) {
            case WizardSteps.MarkerOrigin:
                if (sourceList.length <= 0) {
                    break;
                }

                this.setState({ currentStage: WizardSteps.MarkerSet });
                this.loadSelectedMarkers();
                break;

            case WizardSteps.MarkerSet:
                this.setState({ currentStage: WizardSteps.FieldOperation });
                break;

            case WizardSteps.FieldOperation:
                this.setState({ currentStage: WizardSteps.Confirmation });
                break;

            case WizardSteps.Confirmation:
                this.processMarkerMetaChanges();
                this.setState({ currentStage: WizardSteps.Result });
                break;

            case WizardSteps.Result:
                const { processJobs } = this.props;

                if (!processJobs) {
                    break;
                }

                const { producedJobsList } = this.state;
                processJobs(producedJobsList, sourceList);
                break;

            default:
                break;
        }
    };

    loadSelectedMarkers = () => {
        const { sourceList, allFolders } = this.state;
        const { floorplanProvider, folderProvider } = this.props;
        let elapsed = 0;
        let allMarkers: MarkerList[] = [];

        this.setState({ isContentLoading: true });
        sourceList.forEach(source => {
            if (source.type === 'Floorplan') {
                floorplanProvider.fetchMarkers(source.id).then(data => {
                    allMarkers = allMarkers.concat(
                        data.map((m: Marker) => {
                            const newItem: MarkerList = {
                                folder: allFolders.find(
                                    f => f.id === m.folder_id
                                )!.name,
                                floorplan: sourceList.find(
                                    f => f.id === m.floorplan_id
                                )!.name,
                                checked: false,
                                marker: {
                                    _id: m._id!,
                                    marker_id: m.marker_id,
                                    name: m.name
                                },
                                value: [
                                    {field: 'type', label: ''},
                                    {field: 'square_footage', label: ''}
                                ]
                            };
                            return newItem;
                        })
                    );
                    elapsed += 1;

                    if (elapsed === sourceList.length) {
                        this.setState({
                            allMarkers,
                            isContentLoading: false,
                            searchMarkers: allMarkers
                        });
                    }
                });
                return;
            }
            folderProvider.fetchFolderMarkers(source.id).then(data => {
                const { markers } = data;
                allMarkers = allMarkers.concat(
                    markers.map((m: Marker) => {
                        const newItem: MarkerList = {
                            folder: allFolders.find(f => f.id === m.folder_id)!.name,
                            floorplan: '-',
                            checked: false,
                            marker: {
                                _id: m._id!,
                                marker_id: m.marker_id,
                                name: m.name
                            },
                            value: [
                                {field: 'type', label: ''},
                                {field: 'square_footage', label: ''}
                            ]
                        };
                        return newItem as MarkerList;
                    })
                );
                elapsed += 1;

                if (elapsed === sourceList.length) {
                    this.setState({
                        allMarkers,
                        isContentLoading: false,
                        searchMarkers: allMarkers
                    });
                }
            });
        });
    };

    checkMarker = (
        list: number,
        marker: MarkerList,
        event?: React.ChangeEvent<HTMLInputElement>
    ) => {
        if (list === 0) {
            const { allMarkers } = this.state;
            const markerIndex = allMarkers.findIndex(
                m => m.marker.marker_id === marker.marker.marker_id
            );
            allMarkers[markerIndex].checked = event
                ? event.target.checked
                : !allMarkers[markerIndex].checked;
            this.setState({ allMarkers });
        } else {
            const { operationMarkers } = this.state;
            const markerIndex = operationMarkers.findIndex(
                m => m.marker.marker_id === marker.marker.marker_id
            );
            operationMarkers[markerIndex].checked = event
                ? event.target.checked
                : !operationMarkers[markerIndex].checked;
            this.setState({ operationMarkers });
        }
    };

    handleAllSearch = () => {
        let { searchMarkers, operationMarkers } = this.state;
        operationMarkers = operationMarkers.concat(searchMarkers);
        searchMarkers = [];
        this.setState({
            operationMarkers,
            searchMarkers,
            allOperationMarkers: operationMarkers
        });
    };

    handleAllOperations = () => {
        let { searchMarkers, operationMarkers } = this.state;
        searchMarkers = searchMarkers.concat(operationMarkers);
        operationMarkers = [];
        this.setState({
            operationMarkers,
            searchMarkers,
            allOperationMarkers: operationMarkers
        });
    };

    handleCheckedRight = () => {
        let { searchMarkers, operationMarkers } = this.state;
        const selected = searchMarkers.filter(m => m.checked);
        operationMarkers = operationMarkers.concat(selected);
        searchMarkers = searchMarkers.filter(m => !m.checked);
        this.setState({
            operationMarkers,
            searchMarkers,
            allOperationMarkers: operationMarkers
        });
    };

    handleCheckedLeft = () => {
        let { searchMarkers, operationMarkers } = this.state;
        const selected = operationMarkers.filter(m => m.checked);
        searchMarkers = searchMarkers.concat(selected);
        operationMarkers = operationMarkers.filter(m => !m.checked);
        this.setState({
            operationMarkers,
            searchMarkers,
            allOperationMarkers: operationMarkers
        });
    };

    formatNumber = (value: string): number => {
        // Check if the string is a valid number
        if (!/^-?\d+(\.\d+)?$/.test(value)) {
            return 0;
        }
    
        // Convert to a number and truncate decimals
        const numberValue = Math.abs(Math.trunc(Number(value)));
        
        return numberValue;
    };

    processMarkerMetaChanges = () => {
        const {
            operationMarkers,
            selectedField,
            producedJobsList
        } = this.state;
        const {
            rootStore: { adminStore },
            currentAccount,
            jobProvider
        } = this.props;

        if (!selectedField) {
            return;
        }
        const markerIds = operationMarkers.map(e => {
            return e.marker.marker_id
        });
        const markerValues = operationMarkers.map(e => {
            const field = e.value.find(j => j.field === selectedField.field);
            return field ? selectedField.field === 'square_footage' ? this.formatNumber(field.label) : field.label : '';
        });
        const userIdentifiers = adminStore.userIdentifiers!;
        const jobPayload: JobRequest = {
            user_created_id: userIdentifiers.user_id,
            account_id: currentAccount.accountId,
            job_type: `${JobTypes.MarkerMetaBulkUpdates}`,
            additional_params: {
                marker_ids: markerIds,
                field_values: markerValues,
                meta_field: selectedField.field,
            }
        };

        this.setState({
            isContentLoading: true,
            currentStage: WizardSteps.Result
        });
        jobProvider.scheduleJob(jobPayload)
            .then((result) => {
                const newTask: RunningTasks = {
                    account: currentAccount,
                    export_name: `Updating ${selectedField.field} on (${markerIds.length}) markers`,
                    export_id: result.job_id,
                    type: JobTypes.MarkerMetaBulkUpdates,
                    task_id: result.job_id,
                    status: 102,
                    startedAt: Date.now(),
                    elapsedTime: 0,
                    progress: 0,
                    timer: 1500,
                    static_progress_count: 0,
                    set_to_stop: false
                };

                producedJobsList.push(newTask);
                this.setState({
                    isContentLoading: false,
                    processedMarkers: markerIds.length
                });
            }).catch((error) => {
                // tslint:disable-next-line:no-console
                console.error(error);
                this.setState({
                    failedMarkers: markerIds.length
                });
            });
    };

    handleAllMarkerSearchCategory = (
        event: React.ChangeEvent<{ value: unknown }>
    ) => {
        this.setState({
            allMarkersSearchCategory: event.target.value as MarkerSearchCategory
        });
    };

    handleSelectedMarkerSearchCategory = (
        event: React.ChangeEvent<{ value: unknown }>
    ) => {
        this.setState({
            selectedMarkersSearchCategory: event.target
                .value as MarkerSearchCategory
        });
    };

    handleInputFieldSelect = (
        event: React.ChangeEvent<{ value: unknown }>
    ) => {
        this.setState({
            selectedField: AVAILABLE_FIELDS.find(e => e.field === event.target.value)
        });
    };

    onAllMarkersSearch = (
        event: React.ChangeEvent<HTMLInputElement> &
            React.KeyboardEvent<HTMLInputElement>
    ) => {
        const searchTxt = event.target.value.toLowerCase();
        this.setState({ allMarkersSearch: event.target.value });

        const {
            allMarkersSearchCategory,
            allMarkers,
            operationMarkers
        } = this.state;

        const searchset = allMarkers.filter(
            e =>
                operationMarkers.findIndex(
                    j => j.marker.marker_id === e.marker.marker_id
                ) <= -1
        );

        if (searchTxt === '' || searchTxt.trim() === '') {
            this.setState({ searchMarkers: searchset });
            return;
        }

        switch (allMarkersSearchCategory) {
            case 'MARKER_NAME':
                this.setState({
                    searchMarkers: searchset.filter(
                        e => e.marker.name.toLowerCase().indexOf(searchTxt) > -1
                    )
                });
                break;
            case 'MARKER_ID':
                this.setState({
                    searchMarkers: searchset.filter(
                        e =>
                            e.marker.marker_id
                                .toLowerCase()
                                .indexOf(searchTxt) > -1
                    )
                });
                break;
            case 'FOLDER_NAME':
                this.setState({
                    searchMarkers: searchset.filter(
                        e => e.folder.toLowerCase().indexOf(searchTxt) > -1
                    )
                });
                break;
            case 'FLOORPLAN_NAME':
                this.setState({
                    searchMarkers: searchset.filter(
                        e => e.floorplan.toLowerCase().indexOf(searchTxt) > -1
                    )
                });
                break;

            default:
                break;
        }
    };

    onSelectedMarkersSearch = (
        event: React.ChangeEvent<HTMLInputElement> &
            React.KeyboardEvent<HTMLInputElement>
    ) => {
        const searchTxt = event.target.value.toLowerCase();
        this.setState({ selectedMarkersSearch: event.target.value });

        const {
            selectedMarkersSearchCategory,
            allOperationMarkers
        } = this.state;

        const searchset = allOperationMarkers;

        if (searchTxt === '' || searchTxt.trim() === '') {
            this.setState({ operationMarkers: searchset });
            return;
        }

        switch (selectedMarkersSearchCategory) {
            case 'MARKER_NAME':
                this.setState({
                    operationMarkers: searchset.filter(
                        e => e.marker.name.toLowerCase().indexOf(searchTxt) > -1
                    )
                });
                break;
            case 'MARKER_ID':
                this.setState({
                    operationMarkers: searchset.filter(
                        e =>
                            e.marker.marker_id
                                .toLowerCase()
                                .indexOf(searchTxt) > -1
                    )
                });
                break;
            case 'FOLDER_NAME':
                this.setState({
                    operationMarkers: searchset.filter(
                        e => e.folder.toLowerCase().indexOf(searchTxt) > -1
                    )
                });
                break;
            case 'FLOORPLAN_NAME':
                this.setState({
                    operationMarkers: searchset.filter(
                        e => e.floorplan.toLowerCase().indexOf(searchTxt) > -1
                    )
                });
                break;

            default:
                break;
        }
    };

    centerScroll = (evt: React.UIEvent<HTMLElement>) => {
        this.setState({
            treeScrollPosition: evt.currentTarget.scrollTop
        });
    };

    OnCSVParsed = (results: Papa.ParseResult<string[]>) => {
        const { data } = results;

        if (data.length <= 1) {
            this.setState({
                isNotificationModalOpened: true,
                notificationModalHeader: 'CSV import error',
                notificationModalText: 'No valid marker were found',
                isContentLoading: false,
            });
            return;
        }

        const checkMarker = data[0] as any;

        if (!checkMarker.marker_id || !checkMarker.marker_name || !(checkMarker.room_type || checkMarker.square_footage)) {
            this.setState({
                isNotificationModalOpened: true,
                notificationModalHeader: 'CSV import error',
                notificationModalText: 'Invalid CSV, please check your file, marker export format is required',
                isContentLoading: false,
            });
            return;
        }

        const markerList: MarkerList[] = data.map((e: any) => {
            const markerItem: MarkerList = {
                marker: {
                    _id: `marker::${e.marker_id}`,
                    marker_id: e.marker_id,
                    name: e.marker_name
                },
                floorplan: '-',
                folder: e.building_name ? e.building_name : '-',
                checked: false,
                value: [
                    {field: 'type', label: e.room_type ? e.room_type : ''},
                    {field: 'square_footage', label: e.square_footage ? e.square_footage : ''}
                ]
            };

            return markerItem;
        });
        
        this.setState({
            allMarkers: markerList,
            isContentLoading: false,
            searchMarkers: markerList,
            currentStage: WizardSteps.MarkerSet
        });
    };

    OnCsvTransformHeader = (header: string): string => {
        return header
            .replace(new RegExp(' ', 'g'), '_')
            .toLocaleLowerCase();
    };

    OnImportFromCSVClicked = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ isContentLoading: true });
        const files = event.target.files;
        if (!files) {
            return;
        }

        const csvFile = files[0];
        Papa.parse(csvFile, {
            complete: this.OnCSVParsed,
            header: true,
            transformHeader: this.OnCsvTransformHeader,
            skipEmptyLines: 'greedy'
        });
    };

    onFieldInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => {
        const { operationMarkers, selectedField } = this.state;

        const markerUpdate = operationMarkers[index];
        const fieldToUpdate = markerUpdate.value.find(e => e.field === selectedField?.field);

        if (!fieldToUpdate) {
            return;
        }

        fieldToUpdate.label = event.target.value;

        this.setState({
            operationMarkers
        });
    };

    onNotificationModalClicked = () => {
        this.setState({isNotificationModalOpened: false});   
    };

    public render() {
        const { className, classes } = this.props;
        const {
            currentStage,
            searchMarkers,
            operationMarkers,
            currentNodeTree,
            treeIsLoading,
            sourceList,
            isContentLoading,
            failedMarkers,
            processedMarkers,
            allMarkersSearch,
            allMarkersSearchCategory,
            selectedMarkersSearch,
            selectedMarkersSearchCategory,
            allOperationMarkersError,
            treeScrollPosition,
            selectedField,
            isNotificationModalOpened,
            notificationModalHeader,
            notificationModalText
        } = this.state;

        const flooplanListItems = sourceList
            ? sourceList.map(item => {
                  return new SimpleListItem(
                      item.id,
                      `${item.type}: ${item.name}`,
                      item.id
                  );
              })
            : [];

        return (
            <div
                className={`${classes.root} ${className}`}
                data-testid="meta-fields-update-wizard"
            >
                <div className={`${classes.container}`}>
                    <Stepper
                        activeStep={currentStage}
                        className={classes.stepsHolder}
                        alternativeLabel
                    >
                        {WIZARD_STEPS.map(step => (
                            <Step key={step.id}>
                                <StepLabel>{step.label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                    <div className={classes.stepsContentHolder}>
                        {currentStage === WizardSteps.MarkerOrigin ? (
                            <React.Fragment>
                                <Grid container spacing={1} alignItems="flex-start" className={classes.gridHolders} data-testid="origin-step">
                                <Grid item xs={8} className={classes.gridHolders}>
                                    <Typography
                                        variant='h6'
                                        className={`${classes.heading} ${classes.ellipsis}`}
                                    >
                                        Select floorplans
                                    </Typography>
                                    <Paper
                                        className={classes.treePaper}
                                        onScrollCapture={this.centerScroll}
                                    >
                                        <NodesTreeView
                                            treeData={currentNodeTree}
                                            isLoading={treeIsLoading}
                                            onNodeSelected={this.onNodeSelected}
                                            onNodeToggled={this.onNodeToggled}
                                            className={classes.treeContainer}
                                            loadingOverlayPosition={treeScrollPosition}
                                        />
                                    </Paper>
                                </Grid>
                                <Grid item xs={4} data-testid="selected-origin-documents">
                                    <Typography
                                        variant='h6'
                                        className={`${classes.heading} ${classes.ellipsis}`}
                                    >
                                        Floorplans / Folders selected
                                    </Typography>
                                    {
                                        allOperationMarkersError &&
                                            <Alert severity="error">
                                                <AlertTitle>Error</AlertTitle>
                                                Unable to load all markers in this account
                                            </Alert>
                                    }
                                    <SimpleListPagination
                                        className={classes.simpleList}
                                        keepItemSelected={false}
                                        items={flooplanListItems}
                                        canDeleteListItem={true}
                                        onDeleteItemClick={this.deleteSourceList}
                                        rowsPerPage={10}
                                        totalItems={flooplanListItems.length}
                                        offset={0}
                                        noItemsLabel="Nothing selected"
                                    />
                                </Grid>
                            </Grid>
                        </React.Fragment>
                        ) : currentStage === WizardSteps.MarkerSet ? (
                            <div data-testid="marker-step">
                                <CCSpinner
                                    label="Loading Markers"
                                    className={classes.progressContainer}
                                    loading={isContentLoading}
                                    size={200}
                                >
                                    <Grid
                                        container
                                        spacing={2}
                                        justifyContent="center"
                                        alignItems="center"
                                        className={classes.root}
                                    >
                                        <Grid item xs={4} data-testid="total-marker-holder">
                                            <div className={classes.searchInputHolder}>
                                                <FormControl className={classes.searchCategoryInput}>
                                                    <InputLabel id="all-search-label">Select by: </InputLabel>
                                                    <Select
                                                        labelId="all-search-label"
                                                        id="all-search-category"
                                                        data-testid="all-search-category"
                                                        value={allMarkersSearchCategory}
                                                        onChange={this.handleAllMarkerSearchCategory}
                                                    >
                                                    <MenuItem value="MARKER_NAME">Marker Name</MenuItem>
                                                    <MenuItem value="MARKER_ID">Marker ID</MenuItem>
                                                    <MenuItem value="FOLDER_NAME">Folder Name</MenuItem>
                                                    <MenuItem value="FLOORPLAN_NAME">Floorplan Name</MenuItem>
                                                    </Select>
                                                </FormControl>
                                                <InputBase
                                                    className={classes.searchInput}
                                                    placeholder="Search markers"
                                                    value={allMarkersSearch}
                                                    onChange={this.onAllMarkersSearch}
                                                    data-testid="all-search-input"
                                                />
                                            </div>
                                            <Paper className={classes.treePaper}>
                                                {
                                                    searchMarkers.length <= 0 ?
                                                    <div className={classes.emptyMarkerHolder}>
                                                        <Typography variant="h6" component="h1">
                                                            No markers to select
                                                        </Typography>
                                                    </div>
                                                    :
                                                    searchMarkers.map(m => {
                                                        return (
                                                        <Card className={classes.markerCard}>
                                                            <CardActionArea onClick={(e) => this.checkMarker(0, m)}>
                                                                <CardContent className={classes.markerCardBody}>
                                                                    <Checkbox
                                                                        className={classes.markerCheckbox}
                                                                        checked={m.checked}
                                                                        onChange={(e) => this.checkMarker(0, m, e)}
                                                                    />
                                                                    <Typography variant="h6" component="h6">
                                                                        {m.marker.name}
                                                                    </Typography>
                                                                    <Typography color="textSecondary">
                                                                        {m.marker.marker_id}
                                                                    </Typography>
                                                                    <Typography variant="body2" component="p">
                                                                        {m.folder}
                                                                        <br />
                                                                        {m.floorplan}
                                                                    </Typography>
                                                                </CardContent>
                                                            </CardActionArea>
                                                        </Card>
                                                        );
                                                    })
                                                }
                                            </Paper>
                                            <div className={classes.footNote}>
                                                {searchMarkers.length} Total Markers
                                            </div>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Grid container direction="column" alignItems="center">
                                            <Button
                                                variant="contained"
                                                size="small"
                                                color="secondary"
                                                className={classes.transferButton}
                                                onClick={this.handleAllSearch}
                                                disabled={searchMarkers.length === 0}
                                                aria-label="move all right"
                                                data-testid="move-all-right"
                                            >
                                                ≫
                                            </Button>
                                            <Button
                                                variant="contained"
                                                size="small"
                                                color="secondary"
                                                className={classes.transferButton}
                                                onClick={this.handleCheckedRight}
                                                disabled={searchMarkers.filter(m => m.checked).length === 0}
                                                aria-label="move selected right"
                                                data-testid="move-selected-right"
                                            >
                                                &gt;
                                            </Button>
                                            <Button
                                                variant="contained"
                                                size="small"
                                                color="secondary"
                                                className={classes.transferButton}
                                                onClick={this.handleCheckedLeft}
                                                disabled={operationMarkers.filter(m => m.checked).length === 0}
                                                aria-label="move selected left"
                                                data-testid="move-selected-left"
                                            >
                                                &lt;
                                            </Button>
                                            <Button
                                                variant="contained"
                                                size="small"
                                                color="secondary"
                                                className={classes.transferButton}
                                                onClick={this.handleAllOperations}
                                                disabled={operationMarkers.length === 0}
                                                aria-label="move all left"
                                                data-testid="move-all-left"
                                            >
                                                ≪
                                            </Button>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={4} data-testid="selected-marker-holder">
                                            <div className={classes.searchInputHolder}>
                                                <FormControl className={classes.searchCategoryInput}>
                                                    <InputLabel id="selected-search-label">Select by: </InputLabel>
                                                    <Select
                                                        labelId="selected-search-label"
                                                        id="selected-search-category"
                                                        data-testid="selected-search-category"
                                                        value={selectedMarkersSearchCategory}
                                                        onChange={this.handleSelectedMarkerSearchCategory}
                                                    >
                                                    <MenuItem value="MARKER_NAME">Marker Name</MenuItem>
                                                    <MenuItem value="MARKER_ID">Marker ID</MenuItem>
                                                    <MenuItem value="FOLDER_NAME">Folder Name</MenuItem>
                                                    <MenuItem value="FLOORPLAN_NAME">Floorplan Name</MenuItem>
                                                    </Select>
                                                </FormControl>
                                                <InputBase
                                                    className={classes.searchInput}
                                                    placeholder="Search markers"
                                                    value={selectedMarkersSearch}
                                                    onChange={this.onSelectedMarkersSearch}
                                                    data-testid="selected-search-input"
                                                />
                                            </div>  
                                            <Paper className={classes.treePaper}>
                                                {
                                                    operationMarkers.length <= 0 ?
                                                    <div className={classes.emptyMarkerHolder}>
                                                        <Typography variant="h6" component="h1">
                                                            No markers selected
                                                        </Typography>
                                                    </div>
                                                    :
                                                    operationMarkers.map(m => {
                                                        return (
                                                        <Card className={classes.markerCard}>
                                                            <CardActionArea onClick={(e) => this.checkMarker(1, m)}>
                                                                <CardContent className={classes.markerCardBody}>
                                                                    <Checkbox
                                                                        className={classes.markerCheckbox}
                                                                        checked={m.checked}
                                                                        onChange={(e) => this.checkMarker(1, m, e)}
                                                                    />
                                                                    <Typography variant="h6" component="h6">
                                                                        {m.marker.name}
                                                                    </Typography>
                                                                    <Typography color="textSecondary">
                                                                        {m.marker.marker_id}
                                                                    </Typography>
                                                                    <Typography variant="body2" component="p">
                                                                        {m.folder}
                                                                        <br />
                                                                        {m.floorplan}
                                                                    </Typography>
                                                                </CardContent>
                                                            </CardActionArea>
                                                        </Card>
                                                        );
                                                    })
                                                }
                                            </Paper>
                                            <div className={classes.footNote}>
                                                {operationMarkers.length} Total Markers
                                            </div>
                                        </Grid>
                                    </Grid>
                                </CCSpinner>
                            </div>
                        ) : currentStage === WizardSteps.FieldOperation ?
                            <React.Fragment>
                                <Grid container spacing={1} alignItems="flex-start" className={classes.gridHolders} data-testid="field-operation-step">
                                    <Grid item xs={4} />
                                    <Grid item xs={4}>
                                        <FormControl className={classes.metaFieldInput}>
                                            <InputLabel id="field_input_label">Marker Meta Field: </InputLabel>
                                            <Select
                                                labelId="field_input_label"
                                                id="field_input_select"
                                                data-testid="field_input_select"
                                                value={selectedField?.field}
                                                onChange={this.handleInputFieldSelect}
                                            >
                                                {
                                                    AVAILABLE_FIELDS.map(e => {
                                                        return (
                                                            <MenuItem value={e.field}>{e.label}</MenuItem>            
                                                        )
                                                    })
                                                }
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={4} />
                                    <Paper className={classes.tablePaper}>
                                        <Grid container spacing={1}>
                                            {
                                                operationMarkers.map((m, i) => {
                                                    return (
                                                        <React.Fragment>
                                                            <Grid item xs={3} className={i % 2 === 1 ? classes.bottomLeft : classes.bottomBorder}>
                                                                <ListItemText
                                                                    primary={m.marker.name} 
                                                                    secondary={m.marker.marker_id}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={3} className={classes.bottomBorder}>
                                                                <TextField
                                                                    className={i % 2 === 1 ? classes.tableInputLast : classes.tableInput}
                                                                    id={`meta_field_input_${m.marker.marker_id}`}
                                                                    data-testid={`meta_field_input_${m.marker.marker_id}`}
                                                                    placeholder={selectedField?.label}
                                                                    variant="outlined"
                                                                    value={m.value.find(e => e.field === selectedField?.field)?.label}
                                                                    onChange={(e) => this.onFieldInputChange(e, i)}
                                                                />
                                                            </Grid>
                                                        </React.Fragment>
                                                    );
                                                })
                                            }
                                        </Grid>
                                    </Paper>
                                </Grid> 
                            </React.Fragment>
                        : currentStage === WizardSteps.Confirmation ? (
                            <React.Fragment>
                                <Grid container spacing={1} alignItems="flex-start" className={classes.gridHolders} data-testid="confirmation-step">
                                    <Grid item xs={3} />
                                    <Grid item xs={6} className={classes.gridHolders}>
                                        <Typography
                                            variant='h6'
                                            className={`${classes.simpleHeading} ${classes.ellipsis}`}
                                        >
                                            Updating markers <b>{selectedField?.label}</b> meta field
                                        </Typography>
                                        <Paper className={classes.treePaper}>
                                            {
                                                operationMarkers.map(m => {
                                                    return (
                                                    <Card className={classes.markerCard}>
                                                        <CardContent className={classes.markerCardBody}>
                                                            <Typography variant="h6" component="h6">
                                                                {m.marker.name}
                                                            </Typography>
                                                            <Typography color="textSecondary">
                                                                {m.marker.marker_id}
                                                            </Typography>
                                                            <Typography variant="body2" component="p">
                                                            <b>{selectedField?.label}</b>: {m.value.find(e => e.field === selectedField?.field)?.label}
                                                            </Typography>
                                                        </CardContent>
                                                    </Card>
                                                    );
                                                })
                                            }
                                        </Paper>
                                        <div className={classes.footNote}>
                                            {operationMarkers.length} Markers
                                        </div>
                                    </Grid>
                                    <Grid item xs={3} />
                                </Grid>
                            </React.Fragment>
                        ) : (
                            <div data-testid="result-step">
                                <CCSpinner
                                    label="Processing Markers"
                                    className={classes.progressContainer}
                                    loading={isContentLoading}
                                    size={200}
                                >
                                    <div className={classes.emptyMarkerHolder}>
                                        <Typography variant="h6" component="h1">
                                            {
                                                processedMarkers > 0 
                                                ?
                                                <React.Fragment>
                                                    All markers have been processed: 
                                                    <br />
                                                    <b>
                                                        Total affected markers {failedMarkers + processedMarkers}
                                                    </b>
                                                    <br />
                                                    <b>
                                                        Successfull({processedMarkers}) / Failed({failedMarkers})
                                                    </b>
                                                </React.Fragment>
                                                :
                                                <React.Fragment>
                                                    No operation has been performed: 
                                                    <br />
                                                    <b>
                                                        No marker has been updated
                                                    </b>
                                                </React.Fragment>
                                            }
                                        </Typography>
                                    </div>
                                </CCSpinner>
                            </div>
                        )}
                    </div>
                    {currentStage === WizardSteps.MarkerOrigin && (
                        <React.Fragment>
                            <input
                                id="csv-button"
                                data-testid="csv-button"
                                className={classes.input}
                                accept=".csv"
                                type="file"
                                onChange={this.OnImportFromCSVClicked}
                            />
                            <label htmlFor="csv-button">
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    className={classes.uploadCSVButton}
                                    component="span"
                                >
                                    <PublishRounded
                                        className={classes.iconKerning}
                                    />
                                    Upload a CSV file
                                </Button>
                            </label>
                        </React.Fragment>
                    )}
                    <Button
                        variant="contained"
                        color="primary"
                        className={classes.nextButton}
                        disabled={this.checkNextStageButton()}
                        onClick={this.goNextStageButton}
                        data-testid="go-next-step-button"
                    >
                        {currentStage !== WizardSteps.Result
                            ? 'Next'
                            : 'Finish'}
                    </Button>
                </div>
                <SimpleDialog
                    open={isNotificationModalOpened}
                    titleText={notificationModalHeader}
                    contentText={notificationModalText}
                    buttonCancelLabel=""
                    onDialogResult={this.onNotificationModalClicked}
                />
            </div>
        );
    }
}

const MUIComponent = withStyles(styles)(MarkerMetaFieldsWizard);
export { MUIComponent as MarkerMetaFieldsWizard };
